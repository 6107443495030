import axios from "axios";
import websiteURL from "@/websiteURL";

export async function getListeMarque(perPage, curentPage) {
  const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/brands";
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.get(apiUrl, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
      params: {
        parent: 0,
        per_page: perPage,
        page: curentPage,
      },
    });
    const marques = response.data;
    const totalMarques = response.headers["x-wp-total"];
    const marqueInfo = { marques, totalMarques };
    for (var i = 0; i < marques.length; i++) {
      marques[i].showDetails = false;
    }
    return marqueInfo;
  } catch (error) {
    // Handle errors, e.g., show an error message
    console.error(
      "Erreur de chargement de la liste des marques, veuillez réessayer ultérieurement:",
      error
    );
  }
}
